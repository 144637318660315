import React, { useCallback, useMemo } from 'react'
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Typography,
  Box,
  useTheme,
  IconButton,
  Drawer as MuiDrawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  CSSObject,
  styled
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  LocationOn as LocationOnIcon,
  AccountBalance as AccountBalanceIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Sync as SyncIcon,
  Payments as PaymentsIcon,
  Payment as PaymentIcon,
  People as PeopleIcon,
  Logout as LogoutIcon,
  AccountTree as AccountTreeIcon,
  Map as MapIcon,
  Policy as PolicyIcon,
  Article as ArticleIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import MuiLink from 'components/MuiLink'
import { useAuth } from 'context/auth/auth'
import { useUser } from 'context/auth/user'
import ProtectedComponent from 'components/ProtectedComponent'
import { User_Role_Enum } from 'api/generated'

export const DRAWER_WIDTH = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

export type HeaderProps = {
  drawerOpen?: boolean
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header: React.FC<HeaderProps> = ({
  drawerOpen,
  setDrawerOpen
}: HeaderProps) => {
  const { t } = useTranslation()
  const { logout } = useAuth()
  const { toggleLayoutType } = useUser()
  const {
    palette: {
      common: { white }
    },
    ...theme
  } = useTheme()
  const location = useLocation()

  const pathname = location?.pathname

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(currentDrawerOpen => !currentDrawerOpen)
  }, [setDrawerOpen])

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false)
  }, [setDrawerOpen])

  const menuItems = useMemo(() => {
    return [
      {
        path: '/users',
        name: 'users',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => (
          <SupervisorAccountIcon color={color} />
        )
      },
      {
        path: '/clients',
        name: 'clients',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => <PeopleIcon color={color} />
      },
      {
        path: '/loans',
        name: 'loans',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => <PaymentsIcon color={color} />
      },
      {
        path: '/payments',
        name: 'payments',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => <PaymentIcon color={color} />
      },
      {
        path: '/locations',
        name: 'locations',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => (
          <LocationOnIcon color={color} />
        )
      },
      {
        path: '/payment-methods',
        name: 'paymentMethods',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => (
          <AccountTreeIcon color={color} />
        )
      },
      {
        path: '/map',
        name: 'map',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => <MapIcon color={color} />
      },
      {
        path: '/audits',
        name: 'audit',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => <PolicyIcon color={color} />
      },
      {
        path: '/document-templates',
        name: 'templates',
        protected: true,
        allowedRoles: [User_Role_Enum.SuperAdmin],
        icon: (color?: 'primary' | 'inherit') => <ArticleIcon color={color} />
      },
      {
        path: '/tenants',
        name: 'tenants',
        protected: true,
        allowedRoles: [User_Role_Enum.SuperAdmin],
        icon: (color?: 'primary' | 'inherit') => (
          <AccountBalanceIcon color={color} />
        )
      },
      {
        path: '/profile',
        name: 'profile',
        protected: false,
        allowedRoles: [],
        icon: (color?: 'primary' | 'inherit') => (
          <AccountCircleIcon color={color} />
        )
      }
    ]
  }, [])

  return (
    <>
      <AppBar position='fixed' open={drawerOpen}>
        <Toolbar>
          <IconButton
            color='inherit'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              marginRight: 5,
              ...(drawerOpen && { display: 'none' })
            }}
          >
            <MenuIcon />
          </IconButton>
          <MuiLink to={`/`}>
            <Typography component='h1' variant='h6'>
              <Box color={white}>Credisaas</Box>
            </Typography>
          </MuiLink>
        </Toolbar>
      </AppBar>

      <Drawer variant='permanent' open={drawerOpen}>
        <>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems?.map(item => {
              const isSelected = pathname?.includes(item?.path)

              return (
                <ProtectedComponent
                  isProtected={item?.protected}
                  allowedRoles={item?.allowedRoles}
                  key={item?.path}
                >
                  <MuiLink to={item?.path} color='inherit'>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        selected={isSelected}
                        sx={{
                          minHeight: 48,
                          justifyContent: drawerOpen ? 'initial' : 'center',
                          px: 2.5
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: drawerOpen ? 3 : 'auto',
                            justifyContent: 'center'
                          }}
                        >
                          {item?.icon(isSelected ? 'primary' : 'inherit')}
                        </ListItemIcon>
                        <ListItemText
                          primary={t(item?.name)}
                          primaryTypographyProps={{
                            color: isSelected ? 'primary' : 'text'
                          }}
                          sx={{ opacity: drawerOpen ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </MuiLink>
                </ProtectedComponent>
              )
            })}

            <ListItem disablePadding>
              <ListItemButton onClick={toggleLayoutType}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  <SyncIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('collectorMode')}
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={logout}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('logOut')}
                  sx={{ opacity: drawerOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </>
      </Drawer>
    </>
  )
}

export default Header
